import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import StarRatingComponent from 'react-star-rating-component';
import RaisedButton from 'material-ui/RaisedButton';
import moment from 'moment';
import ReactGA from 'react-ga';
import {RATING_FLOW} from '../utils/constant';
import "./RatingView.scss";

const maxCharacterLength = 255

class RatingView extends Component {
  static propTypes = {
    delivery: PropTypes.object,
    ratingReceived: PropTypes.func,
    pages: PropTypes.array,
    paging: PropTypes.number,
    data: PropTypes.object
  }
  constructor(props) {
    super(props);
    this.state = {
      rating: 0,
      isRated: false,
      isRating: false,
      textFieldValue: '',
      selectedReason: -1,
      enableNextButton: false,
      remainingCommentCharacter: maxCharacterLength,
      badMessage: ['Delivery was Late', 'Poor Presentation or Hygiene', 'Rude or Offensive', 'Poor Communication'],
      goodMessage: ['Fast & Efficient', 'Went above & Beyond', 'Driver was Polite & Friendly', 'Great Communication']
    };
  }
  componentWillMount() {
    if (this.props.data) {
      if (this.props.data.selectedReason === -1 || this.props.data.field) {
        this.setState({isRating: true, rating: this.props.data.rating, enableNextButton: true, selectedReason: this.props.data.selectedReason, enableOtherField: true});
      } else {
        this.setState({isRating: true, rating: this.props.data.rating, enableNextButton: true, enableOtherField: false, selectedReason: this.props.data.selectedReason});
      }
    }
  }
  componentDidMount() {
    if (this.props.data && this.props.data.field && this.refs.commentField !== undefined) {
      this.refs.commentField.value = this.props.data.field;
    }
  }
  onStarClick(nextValue) {
    this.setState({rating: nextValue, selectedReason: -1, enableOtherField: false, enableNextButton: false});
    if (this.refs.commentField !== undefined) {
      this.refs.commentField.value = '';
    }
    ReactGA.event({
      category: RATING_FLOW,
      action: 'Provide Rating'
    });
  }
  startRating() {
    this.setState({isRating: true});
    ReactGA.event({
      category: RATING_FLOW,
      action: 'Click Rate Now Button'
    });
  }
  selectReason(index) {
    if (this.state.rating < 4) {
      if (index === 0) {
        this.setState({selectedReason: index, enableOtherField: false, enableNextButton: true});
      } else {
        this.setState({selectedReason: index, enableOtherField: true, enableNextButton: false});
      }
    } else {
      this.setState({selectedReason: index, enableOtherField: false, enableNextButton: true});
    }
    if (this.refs.commentField !== undefined) {
      this.refs.commentField.value = '';
      this.setState({remainingCommentCharacter: maxCharacterLength});
    }
  }
  _handleTextFieldChange = (e) => {
    if ((e.target.value.length - 1) === maxCharacterLength) {
      e.preventDefault();
      return
    }

    this.setState({
      textFieldValue: e.target.value
    });

    this.setState({remainingCommentCharacter: maxCharacterLength - e.target.value.length});

    if (e.target.value !== '') {
      this.setState({enableNextButton: true});
    } else {
      this.setState({enableNextButton: false});
    }
  }

  handleButton = (direction) => {
    if (direction === 'other') {
      this.setState({enableOtherField: true, selectedReason: -1, enableNextButton: false});
    } else if (direction === 'clear') {
      this.refs.commentField.value = '';
      this.setState({enableNextButton: false});
    } else if (direction === 'left') {
      this.setState({rating: 0, selectedReason: -1, enableNextButton: false});
    } else if (this.state.selectedReason !== -1 || (this.refs.commentField !== undefined && this.refs.commentField.value !== '')) {
      let message = '';
      if (this.state.rating < 4) {
        if (this.state.selectedReason === 0) {
          message = this.state.badMessage[this.state.selectedReason];
        } else if (this.state.selectedReason === -1) {
          message = this.refs.commentField.value;
        } else if (this.refs.commentField !== undefined && this.refs.commentField.value !== '') {
          message = this.state.badMessage[this.state.selectedReason] + ' - ' + this.refs.commentField.value;
        }
      } else {
        if (this.refs.commentField !== undefined && this.refs.commentField.value !== '') {
          message = this.refs.commentField.value;
        } else {
          message = this.state.goodMessage[this.state.selectedReason];
        }
      }
      ReactGA.event({
        category: RATING_FLOW,
        action: 'Submit Reason'
      });
      this.props.ratingReceived({data: {rating: this.state.rating, field: this.refs.commentField ? this.refs.commentField.value : null, message: message, selectedReason: this.state.selectedReason}, action: 1});
    }
  }
  render() {
    const boxImage = require('../images/rating.png');
    const starImage = require('../images/star.png');
    const greenStarImage = require('../images/greenStar.png');
    const leafIcon = require('../images/leafIcon.svg');
    return (
      <div className="ratingViewStyle">
        <Helmet title="Delivery Tracking"/>
        {!this.state.isRating && <div>
          <div className="smallPodGroup"><img alt="" className="smallPod" src={this.props.delivery.delivery_proof.photo_url}/><div className="smallMan"/></div>
          <div className="infoLabels">
            <div>
              <div className="mainTitleArea">Woohoo! Your Delivery has been Completed!</div>
              <div className="titleArea"><span className="titleLabel">Delivery ID</span><span className="descLabel">#{this.props.delivery.id}</span></div>
              <div className="titleArea"><span className="titleLabel">Item Description</span><span className="descLabel">{this.props.delivery.item_description}</span></div>
              <div className="titleArea"><span className="titleLabel">Delivered At</span><span className="descLabel">{moment(this.props.delivery.delivered_at).tz(this.props.delivery.time_zone).format('DD MMM YYYY, hh:mma Z')}</span></div>
              <div className="titleArea"><span className="titleLabel">Notes</span>{this.props.delivery.delivery_proof && <span className="descLabel">{this.props.delivery.delivery_proof.note}</span>}</div>
              <div><img alt="" src={leafIcon} style={{height: '13px', marginRight: '8px'}}/><span className="descLabel">Every Sherpa Delivery is carbon offset</span></div>
              <RaisedButton
              label="Rate Now"
              style={{width: '200px', marginTop: '30px'}}
              labelStyle={{fontSize: '12px'}}
              backgroundColor={'#ff9900'} labelColor={'#ffffff'}
              onClick={this.startRating.bind(this)} />
            </div>
          </div>
          <div className="podGroup"><img alt="" className="pod" src={(this.props.delivery.delivery_proof ? this.props.delivery.delivery_proof.photo_url : '')}/><div className="podMan" /></div>
        </div>}
        {this.state.isRating && <div><div className="introLabels">{this.state.rating === 0 && <img alt="" src={boxImage} className="boxInfo"/>}</div>
        <div>
        <StarRatingComponent
        name="rate1"
        starCount={5}
        starColor={'#123456'}
        renderStarIcon={(index) => <span>{(index <= this.state.rating) && <img alt="" className="star" src={greenStarImage} />}{(index > this.state.rating) && <img alt="" className="star" src={starImage} />}</span>}
        emptyStarColor={'#ffffff'}
        value={this.state.rating}
        onStarClick={this.onStarClick.bind(this)}
        /></div>
        {this.state.rating === 0 && <div>
        <h4>How would you rate your delivery experience with {this.props.delivery.courier.first_name}?</h4>
        <div className="dotArea">
        {this.props.pages && <div>{this.props.pages.map((msg, index) => {return (<div className="floatLeft">{msg && index === 0 && <div className="activeDot"/>}{msg && index !== 0 && <div className="inactiveDot"/>}</div>);})}</div>}
        <div className="clear"/></div></div>}
        {this.state.rating > 0 && <div>
        <h4>What's the main reason you gave {this.props.delivery.courier.first_name} this rating?</h4>
        <div className="buttonArea">
        {this.state.rating < 4 && <div>{this.state.badMessage.map((msg, index) => {return (<div onClick={this.selectReason.bind(this, index)}>{this.state.selectedReason === index && <div className="selectedRateButton">{msg}</div>}{this.state.selectedReason !== index && <div className="rateButton">{msg}</div>}</div>);})}</div>}
        {this.state.rating >= 4 && <div>{this.state.goodMessage.map((msg, index) => {return (<div onClick={this.selectReason.bind(this, index)}>{this.state.selectedReason === index && <div className="selectedRateButton">{msg}</div>}{this.state.selectedReason !== index && <div className="rateButton">{msg}</div>}</div>);})}</div>}
        </div>
        <div className="comments">
        {this.state.enableOtherField && <div className="otherFieldArea"><textarea ref="commentField" onChange={this._handleTextFieldChange} className="commentField" placeholder="Please Explain..." maxLength={maxCharacterLength}/></div>}
        {!this.state.enableOtherField && <div className="otherButton" onClick={this.handleButton.bind(this, 'other')}>Other (Leave a Comment)</div>}
        </div>
        <p className="characterCounter">{this.state.remainingCommentCharacter} characters remaining</p>
        <p className="yourFeedBack">Your feedback is precious for us to identify improvements, but we may not respond <br/> directly to it. If you need help with a delivery, please contact us via the Chat below.</p>
        <div><div className="arrowAreas"><RaisedButton
        label="Go Back"
            style={{width: '100px'}}
            labelStyle={{fontSize: '12px'}}
            backgroundColor={'#90a4ae'} labelColor={'#ffffff'}
            onClick={this.handleButton.bind(this, 'left')} className="leftArrow"/>
        {this.props.pages && <div className="dots">{this.props.pages.map((msg, index) => {return (<div className="floatLeft">{msg && index === 1 && <div className="activeDot"/>}{msg && index !== 1 && <div className="inactiveDot"/>}</div>);})}</div>}
        {!this.state.enableNextButton && <RaisedButton label={this.props.paging === this.props.pages.length - 1 ? 'Submit' : 'Next'}
            style={{width: '100px'}}
        labelStyle={{fontSize: '12px'}}
        backgroundColor={'#3f5a67'} labelColor={'#5d7885'} className="rightArrow"/>}
        {this.state.enableNextButton && <RaisedButton label={this.props.paging === this.props.pages.length - 1 ? 'Submit' : 'Next'}
            style={{width: '100px'}}
        labelStyle={{fontSize: '12px'}}
        backgroundColor={'#c4105a'} labelColor={'#ffffff'}
        onClick={this.handleButton.bind(this, 'right')} className="rightArrow"/>}</div></div>
         </div>}
        </div>}
      </div>
    );
  }
}

export default RatingView;
