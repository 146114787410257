import fetch from 'isomorphic-fetch';
import _ from 'underscore';

const methods = ['get', 'post', 'put', 'patch', 'del'];

export function formatUrl(path) {
  return process.env.REACT_APP_SHERPA_HOST + path;
}
function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }
  const error = {message: response.statusText, response: response};
  throw error;
}

export default class ApiClient {
  constructor() {
    methods.forEach((method) =>
    this[method] = (path, data, customHeaders = {'Content-Type': 'application/x-www-form-urlencoded'}) => new Promise((resolve, reject) => {
      const headers = Object.assign({}, {'Accept': 'application/json'}, customHeaders);
      const format = {method: method.toUpperCase(), headers: headers};
      if (method !== 'GET' && data !== undefined && Object.keys(data).length > 0) {
        let formBody = [];
        for (let i = 0; i < Object.keys(data).length; i++) {
          const encodedKey = encodeURIComponent(Object.keys(data)[i]);
          const encodedValue = encodeURIComponent(data[Object.keys(data)[i]]);
          formBody.push(encodedKey + '=' + encodedValue);
        }
        formBody = formBody.join('&');
        format.body = formBody;
      }
      return fetch(formatUrl(path), format)
        .then(checkStatus)
        .then(response => {
          return response.status !== 204 ? response.json() : {};
        })
        .then(json => {
          resolve(json);
        }).catch((error) => {
          if (error.response) {
            error.response.json().then(json => {
              if (json.error) {
                resolve(json);
              } else {
                let errors = json || {errors: [{ message: error.message }]};
                if (error.response.status === 401 && json.errors && _.isArray(json.errors) && json.errors[0].code === 'jwt_token_expired') {
                  errors = {errors: [{ message: 'Your session has expired, please sign in again to continue.', code: 'jwt_token_expired' }]};
                }
                reject(errors);
              }
            });
          } else {
            const errors = {errors: [{ message: error.message }]};
            reject(errors);
          }
        });
    }));
  }
  /*
   * There's a V8 bug where, when using Babel, exporting classes with only
   * constructors sometimes fails. Until it's patched, this is a solution to
   * "ApiClient is not defined" from issue #14.
   * https://github.com/erikras/react-redux-universal-hot-example/issues/14
   *
   * Relevant Babel bug (but they claim it's V8): https://phabricator.babeljs.io/T2455
   *
   * Remove it at your own risk.
   */
  empty() {}
}
