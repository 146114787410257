export const NEW = '0';
export const OPENED = '1';
export const ASSIGNED = '2';
export const PAID = '3';
export const ON_WAY_TO_PICK_UP = '17';
export const PICKED_UP = '4';
export const ON_WAY_TO_DROP_OFF = '18';
export const DELIVERED = '5';
export const CLOSED = '6';
export const CANCELLED = '7';
export const REFUNDED = '8';
export const PARTIALLY_PAID = '9';
export const FAILED = '10';
export const ONHOLD = '11';
export const ARRIVED_PICKUP = '12';
export const ARRIVED_DELIVER = '13';
export const ADD_NOTE_FLOW = 'Add Note Flow';
export const ATTENDANCE_PREFERENCE_FLOW = 'Attendance Preference Flow';
export const MAP_FLOW = 'Map Flow';
export const RATING_FLOW = 'Rating Flow';
export const REASONS = [{name: 'Address Issues', id: 'reason0'}, {name: 'Damaged/Lost Item', id: 'reason1'}, {name: 'ETA Late Delivery', id: 'reason2'}, {name: 'Other', id: 'reason3'}];
