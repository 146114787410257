import React, { Component } from 'react';
import Dialog from 'material-ui/Dialog';
import FontIcon from 'material-ui/FontIcon';
import PropTypes from 'prop-types';
import './AlcoholNoteOverlayPopup.scss';

class AlcoholNoteOverlayPopup extends Component {
  static propTypes = {
    closeView: PropTypes.func,
    helpCentreLink: PropTypes.string,
    checkID: PropTypes.bool,
    forceSightID: PropTypes.bool,
    alcohol: PropTypes.bool,
    sender_phone: PropTypes.string
  };

  handleButton = () => {
    this.props.closeView(false);
  };

  getTextValues() {
    const { alcohol, checkID, forceSightID } = this.props;
    let titleText, subTitleText;

    if(checkID && (forceSightID || alcohol)) {
      titleText = 'ID check required';
      subTitleText = 'Regardless of appearance, your driver must sight valid ID to confirm you are the intended recipient, and note Date of Birth.';
    } else if (checkID) {
      titleText = 'ID check required'
      subTitleText = 'Your driver must sight valid ID to confirm you are the intended recipient.';
    } else if (forceSightID) {
      titleText = 'ID check required for age verification';
      subTitleText = 'Regardless of appearance, your driver must sight valid ID, and note your Date of Birth.';
    } else {
      titleText = 'ID check may be required for age verification';
      subTitleText = 'Regardless of appearance, your driver may request to sight valid ID, and note your Date of Birth.';
    }

    return { titleText, subTitleText };
  }

  render() {
    const { titleText, subTitleText } = this.getTextValues();

    return (
      <div className="wrap">
        <Dialog
          autoDetectWindowHeight
          open
          modal
          contentStyle={{
            width: '100%',
            textAlign: 'center',
            transform: 'translate(0, 0)',
          }}
          bodyStyle={{ padding: 10 }}
          style={{ paddingTop: 0, height: '100vh' }}
        >
          <FontIcon
            className="material-icons"
            style={{ position: 'absolute', right: '10px', top: '18px' }}
            color={'#d3d3d3'}
            onClick={this.handleButton.bind(this)}
          >
            clear
          </FontIcon>
          <h4> {titleText} </h4>
          <p className="paragTitle">{subTitleText}</p>
          {this.props.checkID && (
            <p className="parag">
              <div>
                <b>Unsure if you’re the intended recipient?</b>
                <a target="_blank" rel="noopener noreferrer" href={`tel:${this.props.senderPhone}`}>
                  Contact the Sender
                </a>
              </div>
            </p>
          )}
          <p className="parag">
            <div>
              <a target="_blank" rel="noopener noreferrer" href={this.props.helpCentreLink}>
                What are valid forms of ID?
              </a>
            </div>
          </p>
          <p className="parag parag2">The driver will not take a photo of your ID.</p>
        </Dialog>
      </div>
    );
  }
}

export default AlcoholNoteOverlayPopup;
