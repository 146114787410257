import React, {Component} from 'react';
import PropTypes from 'prop-types';
import './ThanksView.scss';

class ThanksView extends Component {
  static propTypes = {
    data: PropTypes.object
  }
  render() {
    const boxImage = require('../images/rating.png');
    return (
      <div className="thanksStyle">
        <div>
          <img alt="" src={boxImage} className="boxInfo"/>
          <h4 className="titleLabel">Thank You for Your Feedback</h4>
          <div className="descLabel">We love hearing from you, whether it's with good news, or bad.<br/>We strive to deliver a wonderful experience for all of our customers.</div>
        </div>
      </div>
    );
  }
}

export default ThanksView;
