import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './HandoverRuleBanner.scss';

class HandoverRuleBanner extends Component {
  static propTypes = {
    checkID: PropTypes.bool,
    forceSightID: PropTypes.bool,
    onClick: PropTypes.func
  };

  handleButton = () => {
    this.props.onClick();
  };

  getTextValues() {
    const { checkID, forceSightID } = this.props;

    if(checkID) {
      return 'Item(s) can only be left with the intended recipient – driver will check ID';
    } else if (forceSightID) {
      return 'Driver will check ID prior to handover';
    } else {
      return 'Driver may check ID prior to handover';
    }
  }

  render() {
    const bannerMessage = this.getTextValues();

    return (
      bannerMessage &&
        ( <div className='bannerArea'>
            <div className="info-bar">
              <div> {bannerMessage} </div>
              <div className="icon-container">
                <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={this.handleButton}>
                  <rect width="22" height="20" rx="10" fill="#14ABBD"/>
                  <path d="M12.1517 4.29475C11.7115 4.12183 11.2584 4.04163 10.7918 4.04163C10.3252 4.04163 9.85846 4.12183 9.43193 4.29475C8.99182 4.46818 8.59182 4.70829 8.24548 5.02808C7.88558 5.36141 7.60537 5.73485 7.40537 6.17495C7.2986 6.40151 7.23193 6.62807 7.17881 6.85517C7.0986 7.24162 7.4986 7.57495 7.97881 7.57495C8.45902 7.57495 8.85902 7.25517 9.03193 6.89474C9.15172 6.62807 9.32515 6.41453 9.43193 6.32130C9.61838 6.16141 9.81838 6.02807 10.0585 5.93485C10.2986 5.84162 10.5387 5.80152 10.7918 5.80152C11.0449 5.80152 11.2986 5.84162 11.5251 5.93485C11.7653 6.02808 11.9653 6.14839 12.1517 6.32130C12.3382 6.48119 12.4715 6.68119 12.5783 6.89474C12.6715 7.10829 12.7251 7.33485 12.7251 7.57495C12.7251 7.81506 12.6986 8.05516 12.5783 8.25516C12.4715 8.41506 12.3647 8.56194 12.1116 8.78850C11.8715 8.97495 10.5919 9.77495 10.0985 11.5349C9.88499 12.3083 10.2985 12.9750 10.7918 12.9750C11.2720 12.9750 11.6449 12.2818 12.0183 11.5750C12.4319 10.7886 13.0714 10.3219 13.2985 10.0948C13.6850 9.72132 13.9787 9.40153 14.1787 8.97497C14.3787 8.53487 14.4855 8.05519 14.4855 7.57498C14.4855 7.09477 14.3787 6.61509 14.1787 6.17498C13.9787 5.74842 13.6985 5.36144 13.3386 5.02810C12.9918 4.70832 12.5917 4.45516 12.1517 4.29475Z" fill="white"/>
                  <path d="M11.9115 14.9484C11.9115 15.5484 11.4183 16.0416 10.8183 16.0416C10.2183 16.0416 9.72504 15.5484 9.72504 14.9484C9.72504 14.3484 10.2183 13.8552 10.8183 13.8552C11.4183 13.8552 11.9115 14.3484 11.9115 14.9484Z" fill="white"/>
                </svg>
              </div>
            </div>
          </div>
        )
    );
  }
}

export default HandoverRuleBanner;
