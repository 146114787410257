import React, {Component} from 'react';
import RaisedButton from 'material-ui/RaisedButton';
import moment from 'moment';
import {PICKED_UP} from '../utils/constant';
import PropTypes from 'prop-types';
import './AttendanceCheckView.scss';

const INCOMPLITE = 0;
const COMPLETE = 1;
const AVAILABLE = 1;
const LEAVEUNATTENDED = 2;
const RESCHEDULE = 3;
class AttendanceCheckView extends Component {
  static propTypes = {
    delivery: PropTypes.object,
    completeTitle: PropTypes.object,
    inCompleteTitle: PropTypes.object,
    attendanceReceived: PropTypes.func,
    hideAttendanceCheckView: PropTypes.func
  }
  constructor(props) {
    super(props);
    this.state = {
      messages: [{name: this.availableText(), key: AVAILABLE, filtering: false},
        {name: 'Leave unattended (if there\'s a safe place)', key: LEAVEUNATTENDED, filtering: true},
        {name: 'Re-schedule my delivery', key: RESCHEDULE, filtering: false}],
      stage: INCOMPLITE,
      isLate: false,
      selection: null
    };
  }
  componentDidMount() {
    this.filterOptions();
  }

  availableText() {
    const { check_id, force_sight_id, alcohol } = this.props.delivery;

    if(alcohol && !check_id && !force_sight_id) {
      return 'Someone 18+ will be available to accept'
    } if(alcohol && check_id) {
      return 'I am aged 18+ and will be available to accept'
    } else if(check_id) {
      return 'I will be available to accept'
    } else if(force_sight_id) {
      return 'Someone 18+ will be available to accept'
    } else {
      return 'I, or someone, will be available to accept/sign for the delivery'
    }
  }

  filterOptions() {
    if (this.props.delivery && (this.props.delivery.check_id || this.props.delivery.alcohol || this.props.delivery.negotiable_leave_unattended === false || (this.props.delivery.user.user_tracking_preference && !this.props.delivery.user.user_tracking_preference.negotiable_leave_unattended))) {
      this.setState({messages: this.state.messages.filter((message) => message.filtering === false)});
    }
    if (this.props.delivery && this.props.delivery.recipient_instruction && this.props.delivery.recipient_instruction.attendance) {
      this.setState({stage: COMPLETE});
      if (this.props.delivery.state === PICKED_UP && this.props.delivery.delivery_etas && this.props.delivery.delivery_etas.delivery_position !== 1) {
        this.setState({selection: -1});
      }
    }
    if (this.props.delivery.user.user_tracking_preference && this.props.delivery.user.user_tracking_preference.show_delivery_coming === false) {
      if (this.props.delivery.state !== PICKED_UP) {
        this.setState({stage: COMPLETE, selection: 0});
      }
      if (this.props.delivery.state === PICKED_UP && this.props.delivery.delivery_etas && this.props.delivery.delivery_etas.delivery_position !== 1) {
        this.setState({stage: COMPLETE, selection: -1});
      }
    }
    if (this.props.delivery.delivery_etas && this.props.delivery.delivery_etas.delivery_position !== 1 && moment(this.props.delivery.deliver_for).tz(this.props.delivery.time_zone).toDate().getTime() < new Date().getTime()) {
      this.setState({isLate: true});
    }
  }
  selectReason(index) {
    this.setState({selection: index, enableOtherField: false, enableNextButton: true});
  }
  handleButton = () => {
    if (this.state.selection !== null) {
      this.setState({stage: COMPLETE});
      if (this.state.messages[this.state.selection].key !== RESCHEDULE) {
        this.props.attendanceReceived({attendance: this.state.messages[this.state.selection].key});
      }
    }
  }
  showMapNow = () => {
    if (this.props.delivery.state === PICKED_UP && this.props.delivery.delivery_etas && this.props.delivery.delivery_etas.delivery_position === 1) {
      this.props.hideAttendanceCheckView();
    } else if (this.props.delivery.state === PICKED_UP) {
      this.setState({stage: COMPLETE, selection: -1});
    } else {
      this.setState({stage: COMPLETE, selection: 0});
    }
  }
  render() {
    const boxImage = require('../images/box.png');
    const trackImage = require('../images/track.png');
    const tel = `tel:${this.props.delivery.user.phone}`;
    const buttons = this.state.messages ? this.state.messages.map((button, index) => <div onClick={this.selectReason.bind(this, index)}>{this.state.selection === index && <div className="selectedRateButton">{this.state.messages[index].name}</div>}{this.state.selection !== index && <div className="rateButton">{this.state.messages[index].name}</div>}</div>) : [];
    const buttonStyle = this.state.selection !== null ? {background: '#c4105a', color: '#ffffff'} : {background: '#38515e', color: '#778c97'};
    return (
      <div className="wrapper">
        {this.state.stage === INCOMPLITE && <div>
          <h5 className="titleLabel">{this.props.inCompleteTitle}</h5>
          {this.state.isLate && <p className="lateText">Your delivery is still on its way. You will be able to see a real-time ETA when the driver is on-route for this delivery.</p>}
          <p className="subTitleLabel">Please select an option below ASAP:</p>
          {this.state.messages && <div className="buttonArea">
          {buttons}
          <RaisedButton
          label="Submit"
              className="submit"
              labelStyle={{fontSize: '14px', textTransform: 'none'}}
              backgroundColor={buttonStyle.background} labelColor={buttonStyle.color}
              onClick={this.handleButton.bind(this)}/>
          </div>}
        </div>}
        {this.state.stage === COMPLETE && this.state.selection !== this.state.messages.length - 1 && <div><img className="box" alt="" src={trackImage} height="60"/>
        {this.state.selection !== -1 && <div>
        <h5 className="titleLabel">{this.props.completeTitle}</h5>
        {this.state.isLate && <p className="lateText">This delivery is running late. You will be able to see a real-time ETA when the driver is on-route for this delivery.</p>}
        <p className="maxParag">Once your delivery has been picked up, you'll be able to view your delivery ETA.</p></div>}
        {this.state.selection === -1 && <div>
        {!this.props.delivery.delivery_etas && <h1 className="transitTitle">{moment(this.props.delivery.deliver_for).tz(this.props.delivery.time_zone).format('hh:mmA')}</h1>}
        {this.props.delivery.delivery_etas && <p className="transitTitle">You have a delivery scheduled for {this.props.etaTime}</p>}
        {this.state.isLate && <p className="maxParag">This delivery is running late. You will be able to see a real-time ETA when the driver is on-route for this delivery.</p>}
        {!this.state.isLate && <p className="maxParag">Your package has been picked up and is in transit. You'll be able to track it here once it's on-route for delivery.</p>}
        </div>}
        </div>}
        {this.state.stage === COMPLETE && this.state.selection === this.state.messages.length - 1 && <div><img alt="" className="box" src={boxImage} height="60"/><h5 className="titleLabel">To re-schedule your delivery, please contact:</h5>
        <div className="shopTitle">{this.props.delivery && this.props.delivery.user && this.props.delivery.user.business_name &&
        	<span>{this.props.delivery.user.business_name}</span>}{this.props.delivery && this.props.delivery.user && !this.props.delivery.user.business_name &&
        		<span>{this.props.delivery.user.first_name} {this.props.delivery.user.last_name}</span>}</div>
        		<div className="shopNumber">{this.props.delivery && this.props.delivery.user.user_tracking_preference && this.props.delivery.user.user_tracking_preference.hide_business_number !== true && <a href={tel} style={{color: '#ffffff', textDecoration: 'none'}}>{this.props.delivery.user.phone}</a>}</div>
        <p className="rescheduleParag">If you don't call to re-schedule your delivery, we will continue to attempt to deliver. If unsuccessful, you may be charged a return/re-delivery fee.</p>
        <RaisedButton
        label="Submit"
            className="submit"
            labelStyle={{fontSize: '14px', textTransform: 'none'}}
            backgroundColor={buttonStyle.background} labelColor={buttonStyle.color}
            onClick={this.showMapNow.bind(this)}/>
        	</div>}
      </div>
    );
  }
}

export default AttendanceCheckView;