import React, {Component} from 'react';
import OverlayPopup from '../OverlayPopup/OverlayPopup';
import FontIcon from 'material-ui/FontIcon';
import RaisedButton from 'material-ui/RaisedButton';
import PropTypes from 'prop-types';
import './AddNoteView.scss';

class AddNoteView extends Component {
  static propTypes = {
    delivery: PropTypes.object,
    closeView: PropTypes.func,
    noteReceived: PropTypes.func
  }
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      existText: '',
      remaining: 150
    };
  }
  componentDidMount() {
    if (this.props.delivery && this.props.delivery.recipient_instruction && this.props.delivery.recipient_instruction.message) {
      this.refs.noteField.value = this.props.delivery.recipient_instruction.message;
      this.textChanged();
    }
  }
  showPopup = (status) => {
    this.setState({isOpen: status});
  }
  sendNote = () => {
    if (this.refs.noteField.value === '') {
      return;
    }
    this.props.noteReceived({message: this.refs.noteField.value});
  }
  textChanged = () => {
    if (this.refs.noteField.value.length > 150) {
      this.refs.noteField.value = this.refs.noteField.value.substring(0, 150);
    }
    this.setState({existText: this.refs.noteField.value, remaining: 150 - this.refs.noteField.value.length});
  }
  render() {
    const img = require('../images/help_icon.png');
    const sherpaImage = require('../images/sherpa.png');
    const style = {};
    if (this.state.existText === '') {
      style.background = '#38515e';
      style.color = '#778c97';
    } else {
      style.background = '#c4105a';
      style.color = '#ffffff';
    }
    return (
      <div className="wrapper2">
        {this.state.isOpen && <OverlayPopup closeView={this.showPopup.bind(this)}/>}
        <div className="form">
          <h3 className="padding">Add a Delivery Note<img alt="" src={img} style={{marginLeft: '12px'}} onClick={this.showPopup.bind(this, true)}/></h3>
          {this.props.delivery && !this.props.delivery.leave_unattended && (this.props.delivery.alcohol || this.props.delivery.check_id) &&<div className="warningBar">This delivery cannot legally be left unattended. Please make sure you or someone of age is available to receive this delivery in person.</div>}
          <div className="subTitle padding">DRIVER, PLEASE NOTE:</div>
          <FontIcon className="material-icons" style={{position: 'absolute', right: '10px', top: '25px'}} color={'#ffffff'} onClick={this.props.closeView.bind(this)}>clear</FontIcon>
          <div className="margin">
            <textarea ref="noteField" placeholder="i.e. Please call when you arrive and I'll come down to meet you" rows="4" cols="50" onChange={this.textChanged}></textarea>
          </div>
          <div className="rightAlign">{this.state.remaining} Characters Remaining</div>
          <RaisedButton label="Send Note to Driver"
          className="noteButton"
          onClick={this.sendNote.bind(this)}
          labelStyle={{fontSize: '12px', color: style.color, textTransform: 'capitalize'}}
          backgroundColor={style.background}/>
        </div>
        <div className="power">Powered By<br/><img alt="" className="sherpaLogo" src={sherpaImage}/></div>
      </div>
    );
  }
}

export default AddNoteView;
