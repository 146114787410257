import React, {Component} from 'react';
import Dialog from 'material-ui/Dialog';
import FontIcon from 'material-ui/FontIcon';
import PropTypes from 'prop-types';

class OverlayPopup extends Component {
  static propTypes = {
    closeView: PropTypes.func
  }
  handleButton = () => {
    this.props.closeView(false);
  }
  render() {
    const styles = require('./OverlayPopup.scss');
    return (
      <div className={styles.wrap}>
      <Dialog autoDetectWindowHeight open modal contentStyle={{width: '100%', textAlign: 'center', transform: 'translate(0, 0)'}} bodyStyle={{padding: 10}} style={{paddingTop: 0, height: '100vh'}}>
      <FontIcon className="material-icons" style={{position: 'absolute', right: '10px', top: '18px'}} color={'#d3d3d3'} onClick={this.handleButton.bind(this)}>clear</FontIcon>
      <h4 style={{color: '#0097a9', marginBottom: '20px'}}>Adding a Delivery Note</h4>
      <p className={styles.parag}>Please use the delivery notes to give the driver more guidance about delivering your items i.e. if there is loading dock access, whether you'll be available at the time to receive the delivery, etc.</p>
      <p className={styles.parag}>It cannot be used to change the delivery address, or change delivery preferences like 'Authority to Leave' permissions, etc.</p>
      <p className={styles.parag}>To update this information, please use the 'live chat' on the tracking screen to discuss with Sherpa Support.</p>
      </Dialog>
      </div>
    );
  }
}

export default OverlayPopup;
