/* eslint-disable no-undef */
import React, { Component } from 'react';
import {
  GoogleMap,
  withGoogleMap,
  Marker,
  InfoWindow,
  DirectionsRenderer
} from 'react-google-maps';
import PropTypes from 'prop-types';
import _ from 'underscore';

const GoogleMaps = withGoogleMap(props => {
  return (
		<GoogleMap {...props}
		ref={
          (map) => {
            if (map) {
              const to = {
                lat: props.markers[0].position.lat,
                lng: props.markers[0].position.lng
              };
              const toLatLng = new google.maps.LatLng(to.lat, to.lng);
              const bounds = new google.maps.LatLngBounds();
              bounds.extend(toLatLng);
              if (props.markers.length > 1) {
                const sherpa = {
                  lat: props.markers[1].position.lat,
                  lng: props.markers[1].position.lng,
                };
                const sherpaLatLng = new google.maps.LatLng(sherpa.lat, sherpa.lng);
                bounds.extend(sherpaLatLng);
              }
              map.fitBounds(bounds);
              map.panBy(0, 10);
            }
          }
         }
		 onClick={props.onMapClick}
		 >
		 {_.map(props.directions, (route, key) => {
  return (
             <DirectionsRenderer key={key} directions={route} options={{suppressMarkers: true, preserveViewport: true, polylineOptions: {strokeWeight: 1.5, strokeOpacity: 0.8}}} />
           );
})}
		 {props.markers.map((marker, index) => (
		 <Marker
		 {...marker}
		 position={marker.position}
		 onClick={() => props.onMarkerClick(marker)}
		 onRightClick={() => props.onMarkerRightClick(index)}
		 >{marker.showInfo && (
           <InfoWindow onCloseClick={() => props.onMarkerClose(marker)}>
             <div>{marker.name}&nbsp;&nbsp;</div>
           </InfoWindow>
         )}</Marker>
		 ))}
    </GoogleMap>
  );
});

class Map extends Component {
  static propTypes = {
    delivery: PropTypes.object,
  }
  state = {
    mapOptions: {},
    directions: {},
    markers: [
      {
        zIndex: 2000,
        position: {
          lat: parseFloat(this.props.delivery.delivery_address.latitude),
          lng: parseFloat(this.props.delivery.delivery_address.longitude)
        },
        name: this.props.delivery.delivery_address.formatted_address,
        icon: {
          url: require('../images/destination.png'),
          scaledSize: new google.maps.Size(30, 43)
        },
        key: 'b',
        defaultAnimation: 2,
      }, {
        position: {
          lat: this.props.delivery.courier && this.props.delivery.courier.courier_location ? parseFloat(this.props.delivery.courier.courier_location.latitude) : 0,
          lng: this.props.delivery.courier && this.props.delivery.courier.courier_location ? parseFloat(this.props.delivery.courier.courier_location.longitude) : 0
        },
        name: 'Sherpa',
        icon: {
          url: require('../images/pin.png'),
          scaledSize: new google.maps.Size(30, 35)
        },
        key: 'sherpa',
        defaultAnimation: 2,
      }],
  };

  componentWillMount() {
    this.setMapOptions();
  }

  componentWillReceiveProps(newProps) {
    const markers = this.state.markers;
    if (this.state.markers.length === 2 && newProps.delivery.courier && newProps.delivery.courier.courier_location) {
      markers[1].position = {lat: parseFloat(newProps.delivery.courier.courier_location.latitude), lng: parseFloat(newProps.delivery.courier.courier_location.longitude)};
      this.setState({markers: markers});
    }
  }

  setMapOptions = () => {
    const mapOptions = {
      zoomControl: true,
      fullscreenControl: false,
      rotateControl: false,
      mapTypeControl: false,
      mapTypeControlOptions: {
        tyle: google.maps.MapTypeControlStyle.HORIZONTAL_BAR, // eslint-disable-line no-undef
        position: google.maps.ControlPosition.TOP_CENTER, // eslint-disable-line no-undef
      },
      zoomControlOptions: {
        position: google.maps.ControlPosition.RIGHT_CENTER, // eslint-disable-line no-undef
      },
      streetViewControl: true,
      streetViewControlOptions: {
        position: google.maps.ControlPosition.RIGHT_CENTER, // eslint-disable-line no-undef
      },
    };
    this.setState({
      mapOptions: mapOptions
    });
  }

  handleMarkerClick = this.handleMarkerClick.bind(this);
  handleMarkerClose = this.handleMarkerClose.bind(this);

  // Toggle to 'true' to show InfoWindow and re-renders component
  handleMarkerClick(targetMarker) {
    this.setState({
      markers: this.state.markers.map(marker => {
        if (marker === targetMarker) {
          return {
            ...marker,
            showInfo: false,
          };
        }
        return marker;
      }),
    });
  }

  handleMarkerClose(targetMarker) {
    this.setState({
      markers: this.state.markers.map(marker => {
        if (marker === targetMarker) {
          return {
            ...marker,
            showInfo: false,
          };
        }
        return marker;
      }),
    });
  }

  render() {
    const {
      mapOptions,
      directions
    } = this.state;
    const styles = require('./GoogleMaps.scss');
    return (
      <div className={styles.map}>
      <GoogleMaps
      containerElement={
        <div style={{ height: "calc(100vh - 305px - 100px)" }} />
      }
      mapElement={
        <div style={{ height: `100%` }} />
      }
      defaultOptions={mapOptions}
      markers={this.state.markers}
      onMapClick={_.noop}
      onMarkerClick={this.handleMarkerClick}
      onMarkerClose={this.handleMarkerClose}
      onMarkerRightClick={_.noop}
      directions={directions}
      />
      </div>
    );
  }
}

export default Map;
