import React, {Component} from 'react';
import PropTypes from 'prop-types';
import './GoogleReviewThanksView.scss';

class GoogleReviewThanksView extends Component {
  static propTypes = {
    data: PropTypes.object
  }
  render() {
    const boxImage = require('../images/google_review.png');
    const googleReviewURL = 'https://g.page/r/CVgvks6LlksPEAg/review';
    return (
      <div className="page">
        <div className="thanksStyle">
          <div>
            <div className="descLabel">
              Thank you for your feedback! We would truly appreciate it if you would take a moment to share your experience with others publicly on Google.
            </div>
            
            <a href={googleReviewURL}>
              <img alt="" src={boxImage} className="google-review-image"/>
            </a>
            
            <a href={googleReviewURL}>
              <div className='red-button'>
                Leave a Google Review Now
              </div>
            </a>
          </div>
        </div>
      </div>
      
    );
  }
}

export default GoogleReviewThanksView;
