import React, {Component} from 'react';
import PropTypes from 'prop-types';
import RaisedButton from 'material-ui/RaisedButton';
import ReactGA from 'react-ga';
import {RATING_FLOW} from '../utils/constant';
import './FragileView.scss';

class FragileView extends Component {
  static propTypes = {
    pages: PropTypes.array,
    ratingReceived: PropTypes.func,
    paging: PropTypes.number,
    data: PropTypes.object
  }
  constructor(props) {
    super(props);
    this.state = {
      enableNextButton: false,
      enableOtherField: false
    };
  }
  componentWillMount() {
    if (this.props.data.selectedFragile !== undefined) {
      if (this.props.data.selectedFragile === -1) {
        this.setState({enableNextButton: true, selectedFragile: this.props.data.selectedFragile});
      } else {
        this.setState({enableNextButton: true, selectedFragile: this.props.data.selectedFragile});
      }
    }
    if (this.props.data.fragile_comment !== undefined && this.props.data.fragile_comment !== '') {
      this.setState({enableOtherField: true});
    }
  }
  componentDidMount() {
    if (this.state.enableOtherField) {
      this.refs.commentField.value = this.props.data.fragile_comment;
    }
  }
  _handleTextFieldChange = (e) => {
    this.setState({
      textFieldValue: e.target.value
    });
    if (e.target.value !== '') {
      this.setState({enableNextButton: true});
    	} else {
      this.setState({enableNextButton: false});
    }
  }
  handleButton = (direction) => {
    if (direction === 'other') {
      this.setState({enableOtherField: true, enableNextButton: false});
    } else if (direction === 'clear') {
      this.refs.commentField.value = '';
      this.setState({enableNextButton: false});
    } else if (direction === 'left' || direction === 'right') {
      let message = '';
      if (this.state.selectedFragile === -1) {
        return;
      }
      if (direction === 'left') {
        if (this.refs.commentField !== undefined) {
          message = this.refs.commentField.value;
        }
        this.props.ratingReceived({action: -1, data: {fragile_comment: message, selectedFragile: this.state.selectedFragile}});
      } else {
        if (this.refs.commentField !== undefined && this.refs.commentField.value !== '') {
          if (this.refs.commentField.value.length < 2 || this.refs.commentField.value.length > 255) {
            return;
          }
          message = this.refs.commentField.value;
        }
        ReactGA.event({
          category: RATING_FLOW,
          action: 'Submit Fragile Response'
        });
        this.props.ratingReceived({action: 1, data: {fragile_comment: message, selectedFragile: this.state.selectedFragile}});
      }
    }
  }
  selectReason(index) {
    this.setState({selectedFragile: index, enableOtherField: false, enableNextButton: true});
  }
  render() {
    const fragileImage = require('../images/fragile.png');
    const crossImage = require('../images/cross.png');
    return (
      <div className="fragileStyle">
      <div className="introLabels"><img  alt="" src={fragileImage} className="boxInfo"/></div>
      <h4 className="titleLabel">Your delivery was marked as fragile: Did it arrive in good condition?</h4>
      <div className="buttonArea">
      <div onClick={this.selectReason.bind(this, 0)}>{this.state.selectedFragile === 0 && <div className="selectedRateButton">Yes</div>}{this.state.selectedFragile !== 0 && <div className="rateButton">Yes</div>}</div>
      <div onClick={this.selectReason.bind(this, 1)}>{this.state.selectedFragile === 1 && <div className="selectedRateButton">No</div>}{this.state.selectedFragile !== 1 && <div className="rateButton">No</div>}</div>
      </div>
      <div className="comments">
      {this.state.enableOtherField && <div className="otherFieldArea"><input ref="commentField" onChange={this._handleTextFieldChange} className="commentField" placeholder="Please Explain..."/><img  alt="" className="crossField" src={crossImage} onClick={this.handleButton.bind(this, 'clear')}/></div>}
      {!this.state.enableOtherField && <div className="otherButton" onClick={this.handleButton.bind(this, 'other')}>Other (Leave Comment)</div>}
      </div>
      <div><div className="arrowAreas"><RaisedButton
      label="Go Back"
          style={{width: '100px'}}
          labelStyle={{fontSize: '12px'}}
          backgroundColor={'#90a4ae'} labelColor={'#ffffff'}
          onClick={this.handleButton.bind(this, 'left')} className="leftArrow"/>
      {this.props.pages && <div className="dots">{this.props.pages.map((msg, index) => {return (<div className="floatLeft">{msg && index === this.props.paging && <div className="activeDot"/>}{msg && index !== this.props.paging && <div className="inactiveDot"/>}</div>);})}</div>}
      {!this.state.enableNextButton && <RaisedButton label={this.props.paging === this.props.pages.length - 1 ? 'Submit' : 'Next'}
          style={{width: '100px', transition: 'none'}}
      labelStyle={{fontSize: '12px'}}
      backgroundColor={'#3f5a67'} labelColor={'#5d7885'}
      className="rightArrow"/>}
      {this.state.enableNextButton && <RaisedButton label={this.props.paging === this.props.pages.length - 1 ? 'Submit' : 'Next'}
          style={{width: '100px', transition: 'none'}}
      labelStyle={{fontSize: '12px'}}
      backgroundColor={'#c4105a'} labelColor={'#ffffff'}
      onClick={this.handleButton.bind(this, 'right')} className="rightArrow"/>}</div></div>
      </div>
    );
  }
}

export default FragileView;
