import React, { Component } from 'react';
import ApiClient from '../helpers/ApiClient';
import moment from 'moment-timezone';
import Helmet from 'react-helmet';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import muiTheme from '../theme/theme.custom';
import RaisedButton from 'material-ui/RaisedButton';
import RatingView from '../RatingView/RatingView';
import FragileView from '../FragileView/FragileView';
import ID25View from '../ID25View/ID25View';
import ThanksView from '../ThanksView/ThanksView';
import GoogleReviewThanksView from '../GoogleReviewThanksView/GoogleReviewThanksView';
import GoogleMaps from '../GoogleMaps/GoogleMaps';
import AddNoteView from '../AddNoteView/AddNoteView';
import AttendanceCheckView from '../AttendanceCheckView/AttendanceCheckView';
import AlcoholNoteOverlayPopup from '../AlcoholNoteOverlayPopup/AlcoholNoteOverlayPopup';
import HandoverRuleBanner from '../HandoverRuleBanner/HandoverRuleBanner';
import {REASONS, NEW, OPENED, ASSIGNED, PAID, ON_WAY_TO_PICK_UP, PICKED_UP, ON_WAY_TO_DROP_OFF, ARRIVED_PICKUP, ARRIVED_DELIVER, CANCELLED, REFUNDED, PARTIALLY_PAID, FAILED, ONHOLD, DELIVERED, CLOSED, ADD_NOTE_FLOW, MAP_FLOW, ATTENDANCE_PREFERENCE_FLOW} from '../utils/constant';
import ReactGA from 'react-ga';
import PropTypes from 'prop-types';
import './App.css';

const COVID19 = 'This delivery will be contactless to minimise unnecessary physical interaction between you and your driver.';
export default class App extends Component {
  static propTypes = {
    children: PropTypes.object.isRequired,
    location: PropTypes.object,
    history: PropTypes.object
  }

  constructor(props) {
    super(props);
    this.state = {
      businessName: '',
      delivery: null,
      error: null,
      pages: [],
      paging: 1,
      rating: 0,
      isCompleted: false,
      data: null,
      showNoteForm: false,
      showDeliveryComing: true,
      baseToken: null,
      zone: null,
      statusMessage: null,
      etaTime: '',
      inCompleteTitle: '',
      completeTitle: '',
      incompletedStates: [PARTIALLY_PAID, ONHOLD],
      byPickedUpStates: [NEW, OPENED, ASSIGNED, PAID, ON_WAY_TO_PICK_UP, ARRIVED_PICKUP, PICKED_UP, ON_WAY_TO_DROP_OFF, ARRIVED_DELIVER],
      knownStates: [NEW, OPENED, ASSIGNED, PAID, ON_WAY_TO_PICK_UP, PICKED_UP, ARRIVED_PICKUP, ON_WAY_TO_DROP_OFF, ARRIVED_DELIVER, CANCELLED, REFUNDED, PARTIALLY_PAID, FAILED, ONHOLD, DELIVERED, CLOSED],
      failedStates: [CANCELLED, REFUNDED, FAILED],
      alcoholHelpCentre: '',
      showHandOverBanner: false,
      hostName: 'sherpa.net.au',
      senderPhone: null
    };
    this.startTimer = this.startTimer.bind(this);
    this.stopTimer = this.stopTimer.bind(this);
  }

  componentWillMount() {
    const script = document.createElement('script');
    script.setAttribute('type', 'text/javascript');
    script.setAttribute('src', '/zendesk.js');
    document.head.appendChild(script);
    const token = window.location.pathname;
    this.setState({baseToken: token.length > 0 ? token.substring(1, token.length) : token});
    this.retrieveDelivery();
    window.zESettings = {
      webWidget: {
        color: {
          launcherText: '#ffffff'
        }
      }
    };
    this.startTimer();
  }
  startTimer() {
    const timer = setInterval(() => {
      if (this.state.socket && this.state.socket.readyState === WebSocket.CLOSED) {
        this.retrieveDelivery();
      }
      if (this.state.socket && this.state.delivery.state !== PICKED_UP) {
        this.stopTimer();
      }
    }, 300000);
    this.setState({timer: timer});
  }
  stopTimer() {
    clearInterval(this.state.timer);
  }
  retrieveDelivery() {
    return new Promise((resolve, reject) => {
      new ApiClient().get('/track' + window.location.pathname).then(
        (data) => {
          window.reasons = REASONS;
          if (data !== null && data.error) {
            this.setState({error: data.error});
          } else if (data !== null && data.delivery) {
            if (data.delivery.state === ON_WAY_TO_PICK_UP) {
              data.delivery.state = PAID;
            } else if (data.delivery.state === ON_WAY_TO_DROP_OFF) {
              data.delivery.state = PICKED_UP;
            }

            if (!this.state.delivery) {
              ReactGA.initialize('UA-50973971-8');
              window.delivery = data.delivery;
              if (this.state.knownStates.indexOf(data.delivery.state) === -1) {
                data.delivery.state = NEW;
              }
              if (data.delivery && data.delivery.user) {
                if (data.delivery.user.business_name && data.delivery.user.business_name !== '') {
                  this.setState({businessName: data.delivery.user.business_name});
                  ReactGA.set({dimension1: data.delivery.user.business_name});
                } else {
                  this.setState({businessName: data.delivery.user.first_name + ' ' + data.delivery.user.last_name});
                  ReactGA.set({dimension1: data.delivery.user.first_name + ' ' + data.delivery.user.last_name});
                }
              }
              if (data.delivery.alcohol || data.delivery.check_id || data.delivery.force_sight_id) {
                this.setState({showHandOverBanner: true});

                if (data.delivery.time_zone.includes('Pacific')) {
                  this.setState({alcoholHelpCentre: 'https://help.nzuser.sherpadelivery.com/hc/en-us/articles/4409316845709-What-are-the-valid-forms-of-ID-in-New-Zealand-'})
                }
                else {
                  this.setState({alcoholHelpCentre: 'https://help.auuser.sherpadelivery.com/hc/en-us/articles/4409330104717-What-are-the-valid-forms-of-ID-in-Australia-'})
                }

                if(!localStorage.getItem(window.location.pathname)) {
                  this.setState({showAlcoholNote: true});
                  localStorage.setItem(window.location.pathname, 'true');
                }
              }

              ReactGA.pageview(this.state.baseToken);
              let timeWindow = `${moment(data.delivery.ready_at).tz(data.delivery.time_zone).format('hh:mmA')} - ${moment(data.delivery.deliver_for).tz(data.delivery.time_zone).format('hh:mmA')}`;
              let inCompleteTitle = `You have a delivery scheduled for ${moment(data.delivery.deliver_for).tz(data.delivery.time_zone).format('DD MMM YYYY')} ${timeWindow}`;
              let completeTitle = `You have a delivery scheduled for ${moment(data.delivery.deliver_for).tz(data.delivery.time_zone).format('DD MMM YYYY')} ${timeWindow}`;
              let etaTime = '';
              let hideDeliveryWindow = data.delivery.hide_delivery_window;

              if (data.delivery.delivery_etas && data.delivery.delivery_etas.delivery_eta) {
                if ((data.delivery.state === PICKED_UP && data.delivery.delivery_etas && data.delivery.delivery_etas.delivery_position === 1) || data.delivery.state === ARRIVED_DELIVER) {
                  if (moment(data.delivery.deliver_for).tz(data.delivery.time_zone).toDate().getTime() < new Date().getTime()) {
                    if (moment(data.delivery.delivery_etas.delivery_eta).tz(data.delivery.time_zone).toDate().getTime() < new Date().getTime()) {
                       timeWindow = `${moment().tz(data.delivery.time_zone).format('hh:mmA')} - ${moment().tz(data.delivery.time_zone).add(20, 'm').format('hh:mmA')}`;
                       inCompleteTitle = `You have a delivery scheduled for ${moment(data.delivery.deliver_for).tz(data.delivery.time_zone).format('DD MMM YYYY')}, between ${timeWindow}`;
                       completeTitle = `Your driver is on-route for delivery - ETA between ${timeWindow}`;
                     } else {
                       timeWindow = `${moment(data.delivery.delivery_etas.delivery_eta).tz(data.delivery.time_zone).subtract(10, 'm').format('hh:mmA')} - ${moment(data.delivery.delivery_etas.delivery_eta).tz(data.delivery.time_zone).add(10, 'm').format('hh:mmA')}`;
                       inCompleteTitle = `You have a delivery scheduled for ${moment(data.delivery.deliver_for).tz(data.delivery.time_zone).format('DD MMM YYYY')}, ${timeWindow}`;
                       completeTitle = `Your driver is on-route for delivery - ETA between ${timeWindow}`;
                    }
                  } else {
                    if (moment(data.delivery.delivery_etas.delivery_eta).tz(data.delivery.time_zone).toDate().getTime() < new Date().getTime()) {
                      timeWindow = `${moment().tz(data.delivery.time_zone).format('hh:mmA')} - ${moment().tz(data.delivery.time_zone).add(20, 'm').format('hh:mmA')}`;
                      inCompleteTitle = `You have a delivery scheduled for ${moment(data.delivery.deliver_for).tz(data.delivery.time_zone).format('DD MMM YYYY')}, between ${timeWindow}`;
                      completeTitle = `Your driver is on-route for delivery - ETA between ${timeWindow}`;
                    } else {
                      timeWindow = `${moment(data.delivery.delivery_etas.delivery_eta).tz(data.delivery.time_zone).subtract(10, 'm').format('hh:mmA')} - ${moment(data.delivery.delivery_etas.delivery_eta).tz(data.delivery.time_zone).add(10, 'm').format('hh:mmA')}`;
                      inCompleteTitle = `You have a delivery scheduled for ${moment(data.delivery.deliver_for).tz(data.delivery.time_zone).format('DD MMM YYYY')}, between ${timeWindow}`;
                      completeTitle = `Your driver is on-route for delivery - ETA between ${timeWindow}`;
                    }
                  }
                }
              }
              if (moment(0, "HH").diff(moment(data.delivery.deliver_for).tz(data.delivery.time_zone).toDate(), "days") === 0) {
                etaTime = `Today`;
              } else {
                etaTime = moment(data.delivery.deliver_for).tz(data.delivery.time_zone).format('DD MMM YYYY');
              }

              if(hideDeliveryWindow && data.delivery.state === PICKED_UP && ((data.delivery.delivery_etas && data.delivery.delivery_etas.delivery_position !== 1) || !data.delivery.delivery_etas)) {
                inCompleteTitle = `You have a delivery scheduled for ${moment(data.delivery.deliver_for).tz(data.delivery.time_zone).format('DD MMM YYYY')}`;
                completeTitle = `You have a delivery scheduled for ${moment(data.delivery.deliver_for).tz(data.delivery.time_zone).format('DD MMM YYYY')}`;
              } else {
                etaTime = `${etaTime}, ${timeWindow}`;
              }

              this.setState({inCompleteTitle: inCompleteTitle, completeTitle: completeTitle, etaTime: etaTime});
            }
            if (data.delivery.delivery_etas && data.delivery.delivery_etas.delivery_position && data.delivery.delivery_etas.delivery_position === 1 && data.delivery.state === PICKED_UP && data.delivery.user.user_tracking_preference && data.delivery.user.user_tracking_preference.show_delivery_coming === false) {
              this.setState({showDeliveryComing: false, showMap: true});
            }
            this.setState({delivery: data.delivery});
            if (data.delivery.delivery_etas && data.delivery.delivery_etas.delivery_position) {
              this.setState({position: data.delivery.delivery_etas.delivery_position});
            }
            if ((data.delivery.check_id && data.delivery.fragile) || (data.delivery.alcohol && data.delivery.fragile)){
              this.setState({pages: ['RATING', 'RATING', 'IDCHECK', 'FRAGILE']});
            } else if (data.delivery.check_id || data.delivery.alcohol) {
              this.setState({pages: ['RATING', 'RATING', 'IDCHECK']});
            } else if (data.delivery.fragile) {
              this.setState({pages: ['RATING', 'RATING', 'FRAGILE']});
            } else {
              this.setState({pages: ['RATING', 'RATING']});
            }
            if (data.delivery && data.delivery.courier) {
              if (data.delivery.delivery_proxy_number) {
                this.setState({courierPhone: 'tel:' + data.delivery.delivery_proxy_number});
              } else if (data.delivery.courier.mobile_phone) {
                this.setState({courierPhone: 'tel:' + data.delivery.courier.mobile_phone});
              } else {
                this.setState({courierPhone: null});
              }
              if (this.state.delivery.state === PICKED_UP) {
                this.createSocket();
              }
            }

            this.setState({senderPhone: data.delivery.pickup_phone_number || data.delivery.user.phone})

            if (!this.state.showNoteForm && this.state.delivery && this.state.byPickedUpStates.indexOf(this.state.delivery.state) !== -1) {
              if ((this.state.delivery.state === PICKED_UP || this.state.delivery.state === ARRIVED_DELIVER || this.state.showMap) && (data.delivery.contact_type && data.delivery.contact_type === 'contactless')) {
                this.setState({covidMessage: COVID19});
              }
            } else {
              this.setState({covidMessage: null});
            }
            if (data.delivery.time_zone.includes('Pacific')) {
              this.setState({hostName: 'sherpadelivery.com'})
            }
            else {
              this.setState({hostName: 'sherpa.net.au'})
            }
          }
        },
        () => {
        }, ).catch((err)=> {
          reject(err);
        });
    });
  }
  createSocket() {
    const connection = new WebSocket('wss://' + process.env.REACT_APP_WS_HOST + '/cable?token=' + this.state.baseToken);
    connection.onmessage = (evt) => {
      const msg = JSON.parse(evt.data);
      if (msg.message && msg.message.lat && msg.message.lng) {
        const delivery = this.state.delivery;
        delivery.courier.courier_location.latitude = msg.message.lat;
        delivery.courier.courier_location.longitude = msg.message.lng;
        this.setState({delivery: delivery});
      }
      if (msg.message && msg.message.eta) {
        const delivery = this.state.delivery;
        delivery.delivery_etas.delivery_eta = msg.message.eta;
        this.setState({delivery: delivery});
      }
      if (msg.message && msg.message.type && msg.message.type === 'disconnect') {
        this.retrieveDelivery();
      }
      if (msg.message && msg.message.position) {
        this.setState({position: msg.message.position});
      } else if (msg.message && msg.message.eta) {
        this.setState({position: 0});
      }
    };
    connection.onopen = () => {
      const obj = {'command': 'subscribe', 'identifier': JSON.stringify({'channel': 'LocationsChannel'})};
      connection.send(JSON.stringify(obj));
    };
    this.setState({socket: connection});
  }
  ratingReceived = (ratingObj) => {
    const nextPaging = this.state.paging + ratingObj.action;
    if (nextPaging === this.state.pages.length) {
      const data = {...this.state.data, ...ratingObj.data};
      this.setState({data: data});
      this.setState({rating: data.rating});

      this.submitRate(data);
    } else {
      this.setState({paging: nextPaging, data: {...this.state.data, ...ratingObj.data}});
    }
  }
  noteReceived = (noteObj) => {
    const data = {token: this.state.baseToken, ...noteObj};
    return new Promise((resolve, reject) => {
      new ApiClient().put('/recipient_instructions', data).then(
        (value) => {
          if (value !== null && value.error) {
            this.setState({error: value.error});
            alert(value.error);
          } else if (value !== null) {
            const delivery = this.state.delivery;
            const recipientInstruction = (delivery.recipient_instruction) ? delivery.recipient_instruction : {};
            recipientInstruction.message = noteObj.message;
            delivery.recipient_instruction = recipientInstruction;
            this.setState({showNoteForm: false, delivery: delivery});
            this.showMessage('Your note has been added successfully', 3000);
            ReactGA.event({
              category: ADD_NOTE_FLOW,
              action: 'Submit Note'
            });
          }
        },
        () => {
        }, ).catch((err)=> {
          reject(err);
        });
    });
  }
  attendanceReceived = (noteObj) => {
    const data = {token: this.state.baseToken, ...noteObj};
    return new Promise((resolve, reject) => {
      new ApiClient().put('/recipient_instructions', data).then(
        (value) => {
          if (value !== null && value.error) {
            this.setState({error: value.error});
            alert(value.error);
          } else if (value !== null) {
            this.showMessage('Your note has been added successfully', 3000);
            const delivery = this.state.delivery;
            const recipientInstruction = (delivery.recipient_instruction) ? delivery.recipient_instruction : {};
            recipientInstruction.attendance = noteObj.attendance;
            delivery.recipient_instruction = recipientInstruction;
            const covidMessage = (delivery.contact_type && delivery.contact_type === 'contactless') ? COVID19 : null;
            this.setState({delivery: delivery, covidMessage: covidMessage});
            ReactGA.event({
              category: ATTENDANCE_PREFERENCE_FLOW,
              action: 'Click Track My Delivery'
            });
          }
        },
        () => {
        }, ).catch((err)=> {
          reject(err);
        });
    });
  }
  showMessage = (message, seconds) => {
    this.setState({statusMessage: message});
    const timer = setInterval(() => {
      clearInterval(timer);
      this.setState({statusMessage: null});
    	}, seconds);
  };
  submitRate(data) {
    if (this.state.isSubmitting) {
      return null;
    }
    const param = {rating: data.rating, comment: data.message};
    if (this.state.delivery.check_id) {
      param.check_id = (data.selectedID25 === 0) ? true : false;
      if (data.check_id_comment !== undefined) {
        param.check_id_comment = data.check_id_comment;
      }
    }
    if (this.state.delivery.fragile) {
      param.fragile = (data.selectedFragile === 0) ? true : false;
      if (data.fragile_comment !== undefined) {
        param.fragile_comment = data.fragile_comment;
      }
    }
    this.setState({isSubmitting: true});
    return new Promise((resolve, reject) => {
      new ApiClient().patch('/track' + window.location.pathname + '/rate', param).then(
        (value) => {
          if (value !== null && value.error) {
            this.setState({error: value.error});
            alert(value.error);
          } else if (value !== null) {
            this.setState({paging: (this.state.pages.length + 1), isCompleted: true});
          }
          this.setState({isSubmitting: false});
        },
        () => {
          this.setState({isSubmitting: false});
        }, ).catch((err)=> {
          this.setState({isSubmitting: false});
          reject(err);
        });
    });
  }
  handleButton = () => {
    ReactGA.event({
      category: ADD_NOTE_FLOW,
      action: !this.state.showNoteForm ? 'Click Edit Delivery Note' : 'Close Edit Delivery Note'
    });
    this.setState({showNoteForm: !this.state.showNoteForm});
  }
  closeAlcoholNote = (status) => {
    this.setState({showAlcoholNote: status});
  }
  updateState = (key, value) => {
    this.setState({[`${key}`]: value});
  }
  hideAttendanceCheckView = () => {
    this.setState({showDeliveryComing: false, showMap: true});
  }

  setShowAlcoholNote = () => {
    this.setState({showAlcoholNote: true});
  }

  callDriver = () => {
    ReactGA.event({
      category: MAP_FLOW,
      action: 'Call Courier'
    });
  }
  render() {
    const poweredSherpa = require('../images/sherpa.png');
    const phone = require('../images/phone.png');
    const crossImage = require('../images/cross.png');
    let noteText = 'Add a Delivery Note';
    if (this.state.delivery && this.state.delivery.recipient_instruction && this.state.delivery.recipient_instruction.message) {
      noteText = 'Edit Delivery Note';
    }
    const reasons = REASONS.map((reason, index) => (<div className="reason"><p>{reason.name}</p><input type="radio" name="reason" id={reason.id} value={reason.name} className="reasonBox"/></div>));
    return (
	<MuiThemeProvider muiTheme={muiTheme}>
      <div className="App">
      <Helmet title="Delivery Tracking"/>
{this.state.delivery && this.state.delivery.delivered_at &&
  <div>
    <div className="logoArea"><img alt="" src={poweredSherpa} /></div>
    {this.state.pages[this.state.paging] === 'RATING' && <RatingView pages={this.state.pages} paging={this.state.paging} data={this.state.data} delivery={this.state.delivery} ratingReceived={this.ratingReceived}/>}
    {this.state.pages[this.state.paging] === 'FRAGILE' && <FragileView pages={this.state.pages} paging={this.state.paging} data={this.state.data} ratingReceived={this.ratingReceived}/>}
    {this.state.pages[this.state.paging] === 'IDCHECK' && <ID25View pages={this.state.pages} paging={this.state.paging} data={this.state.data} ratingReceived={this.ratingReceived}/>}
    { this.state.isCompleted && this.state.rating <=3  && <ThanksView data={this.state.data}/> }
    { this.state.isCompleted && this.state.rating > 3  &&  <GoogleReviewThanksView /> }
  </div>
}

{this.state.delivery && this.state.incompletedStates && this.state.incompletedStates.indexOf(this.state.delivery.state) !== -1 && <div><div className="errorBackground">
      <div className="errorMessage">Delivery is not yet picked up. It is due to be completed between {moment(this.state.delivery.ready_at).tz(this.state.delivery.time_zone).format('hh:mmA')} and {moment(this.state.delivery.deliver_for).tz(this.state.delivery.time_zone).format('hh:mmA')} on {moment(this.state.delivery.deliver_for).tz(this.state.delivery.time_zone).format('DD/MM/YYYY')}</div>
      <div>
    	<a href={"https://www." + this.state.hostName}><img alt="" className="sherpaLogo" src={poweredSherpa} /></a>
      </div>
      </div>
  </div>
}
{this.state.delivery && this.state.failedStates && this.state.failedStates.indexOf(this.state.delivery.state) !== -1 &&
	<div>
      <div className="errorBackground">
         {this.state.delivery.state === FAILED && <div className="errorMessage">Sorry, your item has been returned to {this.state.businessName}<div>It may have been returned for one of the below reasons:</div><div className="failedMessage"><div>- No one home/not safe to leave<br/>- RSA compliance<br/>- Specified recipient is not available<br/>- Incomplete/wrong delivery address<br/>- Delivery address is closed</div></div></div>}
         {this.state.delivery.state !== FAILED && <div className="errorMessage">Your delivery has been cancelled</div>}
         <div>
            <a href={"https://www." + this.state.hostName}><img alt="" className="sherpaLogo" src={poweredSherpa} /></a>
         </div>
      </div>
    </div>
}
{this.state.error && <div><div className="errorBackground">
  <div className="errorMessage">{this.state.error}</div>
    	<div>
        <a href={"https://www." + this.state.hostName}><img alt="" className="sherpaLogo" src={poweredSherpa} /></a>
      </div>
      </div>
  </div>
}
{this.state.delivery && this.state.covidMessage && <div className="covidBar">{this.state.covidMessage}<img alt="" className="crossField" src={crossImage} onClick={this.updateState.bind(this, 'covidMessage', null)}/></div>}
{!this.state.showNoteForm && this.state.delivery && (this.state.byPickedUpStates.indexOf(this.state.delivery.state) !== -1) &&
    <div className="mapviewContainer"><div className="appHeader">
      {this.state.delivery.user && this.state.delivery.user.logo_url && <img src={this.state.delivery.user.logo_url} className="shopLogo" alt="logo" />}
      <div className="shopInfo">
        <div className="shopTitle">You have a delivery on the way from</div>
        <div className="shopTitleContents">{this.state.delivery && this.state.delivery.user && this.state.delivery.user.business_name &&
        	<span>{this.state.delivery.user.business_name}</span>}{this.state.delivery && this.state.delivery.user && !this.state.delivery.user.business_name &&
        		<span>{this.state.delivery.user.first_name} {this.state.delivery.user.last_name}</span>}</div>
      </div>
    </div>
    {((this.state.position === 1 && this.state.delivery.state === PICKED_UP && this.state.delivery.recipient_instruction && !isNaN(this.state.delivery.recipient_instruction.attendance)) || (this.state.showMap) || (this.state.delivery.state === ARRIVED_DELIVER)) && <div className="appIntro">
    {this.state.delivery && <GoogleMaps delivery={this.state.delivery}/>}
      {this.state.delivery && this.state.delivery.delivery_etas && <div className="etaGroup">
        <span className="etaTitle">ETA</span>
        <span className="etaContents">{this.state.etaTime}</span>
        <div className="etaPosition"><span>Your Delivery is on its Way</span></div>
      </div>}
  </div>}
  {!(this.state.position === 1 && this.state.delivery.state === PICKED_UP && this.state.delivery.recipient_instruction && !isNaN(this.state.delivery.recipient_instruction.attendance)) && this.state.delivery.state !== ARRIVED_DELIVER &&
    this.state.showDeliveryComing && <AttendanceCheckView className="appIntro" etaTime={this.state.etaTime} inCompleteTitle={this.state.inCompleteTitle} completeTitle={this.state.completeTitle} hideAttendanceCheckView={this.hideAttendanceCheckView.bind(this)} attendanceReceived={this.attendanceReceived.bind(this)} delivery={this.state.delivery}/>
  }
  <div className="appFooter">
    {((this.state.position === 1 && this.state.delivery.state === PICKED_UP && this.state.delivery.recipient_instruction && !isNaN(this.state.delivery.recipient_instruction.attendance)) || this.state.delivery.state === ARRIVED_DELIVER) && <div className="courierInfo">
      {this.state.delivery && this.state.delivery.courier && this.state.delivery.courier.photo_url && <img src={this.state.delivery.courier.photo_url} className="courierLogo" alt="logo" />}
        <div className="courierTitleGroup">
          <div className="courierTitle">YOUR COURIER</div>
          <div className="courierTitleContents">{this.state.delivery && this.state.delivery.courier && <span>{this.state.delivery.courier.first_name} {this.state.delivery.courier.last_name}</span>}</div>
        </div>
        {this.state.courierPhone && <div className="phone">
          <a href={this.state.courierPhone} onClick={this.callDriver.bind(this)}><img alt="" src={phone} width="40" height="40"/></a>
        </div>}
      </div>
    }

    { this.state.showHandOverBanner && <HandoverRuleBanner checkID={this.state.delivery.check_id} forceSightID={this.state.delivery.force_sight_id} onClick={this.setShowAlcoholNote}/> }

    <div className="orderGroup" style={{position: 'relative'}}>
      <div className="power" style={{position: 'absolute', right: '0px', bottom: '15px'}}>
      <a href={"https://www." + this.state.hostName}><img alt="" className="sherpaLogo" src={poweredSherpa}/></a>
      </div>
      <div className="order">
        <span className="orderLabel">YOUR ORDER:</span><br/>

        { this.state.delivery && (
          <span className="orderDescription">{this.state.delivery.item_description}</span>
        )}
      </div>
      <RaisedButton label={noteText}
      className="addNote"
      onClick={this.handleButton.bind(this)}
      labelStyle={{fontSize: '12px', textTransform: 'capitalize'}}
      backgroundColor={'#8A969C'} labelColor={'#ffffff'}/>
      </div>
    </div>
  </div>
}
{this.state.showNoteForm && <AddNoteView delivery={this.state.delivery} closeView={this.handleButton.bind(this)} noteReceived={this.noteReceived.bind(this)}/>}
{this.state.statusMessage && <div className="statusBar">{this.state.statusMessage}</div>}
{this.state.delivery && this.state.showAlcoholNote && <AlcoholNoteOverlayPopup closeView={this.closeAlcoholNote.bind(this, false)} helpCentreLink={this.state.alcoholHelpCentre} alcohol={this.state.delivery.alcohol} checkID={this.state.delivery.check_id} forceSightID={this.state.delivery.force_sight_id} senderPhone={this.state.senderPhone}/>}
<div id="pre_chat_modal">
          <div id="modal-dialog" ref="modalDialog">
          		<div className="title">
                  What Can We Help You With?
                	  <svg width="16" height="16" viewBox="0 0 16 16" data-garden-id="buttons.icon" data-garden-version="7.0.2" id="reasonClose"><path stroke="currentColor" stroke-linecap="round" stroke-width="2" d="M3 8h10"></path></svg>
          		</div>
          		 {reasons}
          		<div className="reason">
          			<button id="startTaggedChat" className="startButton">Start Chat</button>
          		</div>
          </div>
        </div>
          </div>
</MuiThemeProvider>
    );
  }
}
